<template>
  <el-popover placement="bottom-start" width="350" trigger="click">
    <template name="—">
      <p class="p-2 m-0 mt-2 font-bold text-color-blank">Buscar por fechas</p>
      <el-radio
        v-for="(date, index) in filterDates"
        :key="index"
        :disabled="loading"
        v-model="rangeDates"
        :label="date.label"
        class="d-block my-2"
        >{{ date.title }}</el-radio
      >
      <el-date-picker
        v-model="customRangedates"
        @change="handleChangeCustomRangedates"
        format="dd-MM-yyyy"
        value-format="yyyy-MM-dd"
        type="daterange"
        v-if="rangeDates === 'dateRages'"
      ></el-date-picker>
    </template>
    <img
      slot="reference"
      :src="icoCalendario"
      alt="filter"
      width="24"
      class="cursor-point"
    />
  </el-popover>
</template>

<script>
import { icoCalendario } from "@/services/resources";

import {
  filterDates,
  currentDayDate,
  lastWeekFromDate,
  currentFromDate,
  currentToDate,
  lastFromMonthDate,
  lastToMonthDate,
  lastYearDate,
  lastFromYearDate,
  lastToYearDate
} from "@/services/DateServices";
export default {
  name: "FilterBetweenDates",
  data() {
    return {
      icoCalendario,
      rangeDates: null,
      searchDates: null,
      customRangedates: null,
      filterDates
    };
  },
  watch: {
    loading(value) {
      this.loading = value;
    },
    rangeDates() {
      this.handleChangeRageDates();
    }
  },
  methods: {
    handleChangeRageDates() {
      this.searchDates = null;

      switch (this.rangeDates) {
        case "currentDay":
          this.searchDates = {
            from: currentDayDate,
            to: currentDayDate
          };
          break;
        case "lastWeek":
          this.searchDates = {
            from: lastWeekFromDate,
            to: currentDayDate
          };
          break;
        case "currentMonth":
          this.searchDates = {
            from: currentFromDate,
            to: currentToDate
          };
          break;
        case "lastMonth":
          this.searchDates = {
            from: lastFromMonthDate,
            to: lastToMonthDate
          };
          break;
        case "currentYear":
          this.searchDates = {
            from: lastYearDate,
            to: currentDayDate
          };
          break;
        case "lastYear":
          this.searchDates = {
            from: lastFromYearDate,
            to: lastToYearDate
          };
          break;
        default:
          break;
      }
      if (this.rangeDates !== "dateRages") this.onAsyncDataForDates();
    },
    onAsyncDataForDates() {
      this.$emit("onAsyncDataForDates", {
        searchDates: this.searchDates
      });
    },
    handleChangeCustomRangedates() {
      this.searchDates = null;
      if (this.customRangedates)
        this.searchDates = {
          from: this.customRangedates[0],
          to: this.customRangedates[1]
        };
      this.onAsyncDataForDates();
    }
  },
  props: {
    loading: {
      default: false,
      type: Boolean
    }
  }
};
</script>

<style lang="scss" scoped>
.el-range-editor {
  width: 100% !important;
}
</style>
