<template>
  <div
    class="wrapper__ccomplete-profile"
    v-if="!userInfoBusinessCompleted || !productAndClientCompleted"
  >
    <b-row>
      <b-col xs="12" lg="12">
        <AlertComponent
          class="m-0 mb-4"
          description="Completa tus datos de perfil y negocio para poder emitir documentos"
        />
        <div class="box-shadow border-radius py-3 px-5 mb-3 bg-color-write">
          <div
            class="item-complete d-flex justify-content-between my-5 cursor-point"
            :class="{ completed: userInfoBusinessCompleted }"
            @click="goPageProfileTrade"
          >
            <div class="item-complete-body d-flex align-items-center">
              <div class="item-complete-icon pr-4">
                <img
                  class="ico-check"
                  :src="
                    userInfoBusinessCompleted
                      ? icoCheckMarkSuccess
                      : icoCheckMark
                  "
                  alt="ico check"
                  width="24"
                />
              </div>
              <div class="item-complete-info">
                <h5 class="h5 m-0 font-bold">
                  Completa la información de tu empresa
                </h5>
                <p class="m-0">3 minutos</p>
              </div>
            </div>
            <div class="item-complete-action">
              <ButtonIcoComponent ico="icoArrowLeft" />
            </div>
          </div>
          <div
            class="item-complete d-flex justify-content-between my-5 cursor-point"
            :class="{ completed: productCompleted }"
            @click="goPage('catalogue.products.add')"
          >
            <div class="item-complete-body d-flex align-items-center">
              <div class="item-complete-icon pr-4">
                <img
                  class="ico-check"
                  :src="productCompleted ? icoCheckMarkSuccess : icoCheckMark"
                  alt="ico check"
                  width="24"
                />
              </div>
              <div class="item-complete-info">
                <h5 class="h5 m-0 font-bold">
                  Crea tus productos o servicios
                </h5>
                <p class="m-0">5 minutos</p>
              </div>
            </div>
            <div class="item-complete-action">
              <ButtonIcoComponent ico="icoArrowLeft" />
            </div>
          </div>
          <div
            class="item-complete d-flex justify-content-between my-5 cursor-point"
            :class="{ completed: clientCompleted }"
            @click="goPage('business.add')"
          >
            <div class="item-complete-body d-flex align-items-center">
              <div class="item-complete-icon pr-4">
                <img
                  class="ico-check"
                  :src="clientCompleted ? icoCheckMarkSuccess : icoCheckMark"
                  alt="ico check"
                  width="24"
                />
              </div>
              <div class="item-complete-info">
                <h5 class="h5 m-0 font-bold">
                  Registra tus clientes y contactos
                </h5>
                <p class="m-0">3 minutos</p>
              </div>
            </div>
            <div class="item-complete-action">
              <ButtonIcoComponent ico="icoArrowLeft" />
            </div>
          </div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import AlertComponent from "@/components/Alerts/Info";
import businessService from "@/modules/dashboard/profile/pages/business/services/index";

import {
  bgDashboard,
  icoCheckMark,
  icoCheckMarkSuccess
} from "@/services/resources";
import ButtonIcoComponent from "../../components/ButtonIco";

export default {
  name: "CompletedInfoProfileComponent",
  data() {
    return {
      icoCheckMark,
      icoCheckMarkSuccess,
      bg: {
        backgroundImage: `url(${bgDashboard})`
      },
      productCompleted: true,
      clientCompleted: true
    };
  },
  computed: {
    userInfoCompleted() {
      return this.$store.getters.userInfoCompleted;
    },
    userInfoBusinessCompleted() {
      return this.$store.getters.userInfoBusinessCompleted;
    },
    productAndClientCompleted() {
      return this.productCompleted && this.clientCompleted;
    }
  },
  methods: {
    goPage(name) {
      this.$router.push({ name });
    },
    goPageProfileTrade() {
      if (this.userInfoBusinessCompleted) return false;
      else
        this.$router.push({
          path: "/dashboard/negocio",
          query: { action: "editar" }
        });
    },
    async getInfoBusinessCompleted() {
      try {
        let response = await businessService.getInfoBusinessCompleted();
        const { success, client_completed, product_completed } = response.data;
        if (success) {
          this.productCompleted = product_completed;
          this.clientCompleted = client_completed;
        }
      } catch (error) {
        return false;
      }
    }
  },
  components: {
    ButtonIcoComponent,
    AlertComponent
  },
  mounted() {
    this.getInfoBusinessCompleted();
  }
};
</script>

<style lang="scss" scoped>
.wrapper__ccomplete-profile {
  // max-width: 725px;
  // margin: auto;

  .wrapper__card-complete-profile {
    background-size: cover;

    .wrapper__card-complete-profile-body {
      min-height: 230px;
      display: flex;
      align-items: flex-end;
      padding: 2rem 4rem;

      h4 {
        line-height: inherit !important;
      }
    }
  }

  .item-complete {
    &.completed {
      .item-complete-info,
      .item-complete-action {
        opacity: 0.5 !important;
      }
    }
  }
}
</style>
