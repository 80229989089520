<template>
  <b-row class="">
    <b-col xs="12" sm="4" md="4" class="mb-3 mb-lg-0">
      <div class="border-radius md__border mb-2 p-3 bg-color-primary h-100">
        <div class="wrapper__number-statistics">
          <div class="wrapper__number-statistics-header">
            <div class="bg-icon-rounded wallet">
              <img :src="icoWallet" alt="wallet" width="24" />
            </div>
          </div>
          <div class="wrapper__number-statistics-body">
            <h1 class="h1 text-color-secondary">
              <FormatAmount :amount="allSales" abbreviate />
            </h1>
            <p class="m-0 text-color-write">
              Total ventas mes <br />
              actual
            </p>
            <small>
              <i class="el-icon-data-line text-color-secondary"></i>
              <span class="text-color-write pl-2 font-bold"
                >Mes anterior <FormatAmount :amount="lastAllSales" abbreviate
              /></span>
            </small>
          </div>
          <div class="wrapper__number-statistics-footer">
            <h5 class="text-right text-color-gray">
              <i class="el-icon-right" @click="goPage('sales.documents')"></i>
            </h5>
          </div>
        </div>
      </div>
    </b-col>
    <b-col xs="12" sm="4" md="4" class="mb-3 mb-lg-0">
      <div class="border-radius md__border mb-2 p-3 bg-color-write h-100">
        <div class="wrapper__number-statistics">
          <div class="wrapper__number-statistics-header">
            <div class="bg-icon-rounded check">
              <img :src="icoCheck" alt="check" width="24" />
            </div>
          </div>
          <div class="wrapper__number-statistics-body">
            <h1 class="h1">
              <FormatAmount :amount="balanceTotal" abbreviate />
              <!-- <span class="h4 text-color-gray"
                >/{{ statsQuick.quote.limit_quotes }}</span
              > -->
            </h1>
            <p class="m-0 text-color-secondary">
              Balance <br />
              Total
            </p>
            <small>
              <!-- <i class="el-icon-data-line text-color-secondary"></i> -->
              <span class="pl-2 text-color-primary font-bold"></span>
            </small>
          </div>
          <div class="wrapper__number-statistics-footer">
            <h5 class="text-right text-color-gray">
              <i
                class="el-icon-right"
                @click="goPage('sales.balance.view')"
              ></i>
            </h5>
          </div>
        </div>
      </div>
    </b-col>
    <b-col xs="12" sm="4" md="4" class="mb-3 mb-lg-0">
      <div class="border-radius md__border mb-2 p-3 bg-color-write h-100">
        <div class="wrapper__number-statistics">
          <div class="wrapper__number-statistics-header">
            <div class="bg-icon-rounded user-circle">
              <img :src="icoUserCircle" alt="user-circle" width="24" />
            </div>
          </div>
          <div class="wrapper__number-statistics-body">
            <h1 class="h1">{{ statsQuick.client.all_clients }}</h1>
            <p class="m-0 text-color-secondary">
              Total de <br />
              Clientes
            </p>
            <small>
              <i class="el-icon-data-line text-color-secondary"></i>
              <span class="pl-2 text-color-primary font-bold"
                >{{ statsQuick.client.stats_clients }}% este mes</span
              >
            </small>
          </div>
          <div class="wrapper__number-statistics-footer">
            <h5 class="text-right text-color-gray">
              <i class="el-icon-right" @click="goPage('business.list')"></i>
            </h5>
          </div>
        </div>
      </div>
    </b-col>
  </b-row>
</template>

<script>
import FormatAmount from "@/components/General/FormatAmount";
import {
  icoWallet,
  icoOptionGray,
  icoCheck,
  icoUserCircle
} from "@/services/resources";
import dashboardService from "../services/dashboardService";

export default {
  name: "NumberStatisticsComponent",
  data: () => ({
    icoOptionGray,
    icoWallet,
    icoCheck,
    icoUserCircle,

    statsQuick: {
      client: {
        all_clients: 0,
        stats_clients: 0
      },
      balance: {
        total: 0
      },
      sales: {
        all_sales: 0,
        stats_sales: 0
      }
    }
  }),
  computed: {
    allSales() {
      return this.statsQuick.sales.all_sales;
    },
    lastAllSales() {
      return this.statsQuick.sales.stats_sales;
    },
    balanceTotal() {
      return this.statsQuick.balance.total;
    }
  },
  methods: {
    goPage(name) {
      this.$router.push({ name });
    },
    async getStatsQuick() {
      try {
        let response = await dashboardService.getStatsQuick().finally(() => {});
        if (response.data.data) {
          this.statsQuick = response.data.data;
        }
      } catch (error) {
        return false;
      }
    }
  },
  mounted() {
    this.getStatsQuick();
  },
  components: {
    FormatAmount
  }
};
</script>

<style lang="scss" scoped></style>
