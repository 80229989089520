<template>
  <div class="wrapper__plan-card-current border-radius h-100" :style="bg">
    <div class="wrapper__plan-card-current-body h-100">
      <div class="wrapper__plan-card-current-info">
        <h4 class="text-color-write font-bold m-0">Hola {{ getUser.name }}</h4>
        <p class="text-color-write m-0 d-flex align-items-baseline">
          <span v-if="!isPlanSubscriptions">Tienes el plan gratuito</span>
          <span v-else class="font-bold"
            >Mydessk te ayudará a gestionar tus ventas</span
          >
          <img :src="icoCool" alt="cool" width="24" />
        </p>
      </div>
      <div
        class="wrapper__plan-card-current-action"
        v-if="!isPlanSubscriptions"
      >
        <button
          class="btn md__btn-primary font-semi-bold"
          @click="goRouter('plans')"
        >
          Actualizar
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { bgDashboardPlan, icoCool } from "@/services/resources";

export default {
  name: "PlansCardComponent",
  data: () => ({
    icoCool,
    bg: {
      backgroundImage: `url(${bgDashboardPlan})`,
      backgroundSize: "cover"
    }
  }),

  computed: {
    getUser() {
      return this.$store.getters.getUser;
    },
    isPlanSubscriptions() {
      return this.$store.getters.isPlanSubscriptions;
    }
  },

  methods: {
    goRouter(name) {
      this.$router.push({ name });
    }
  }
};
</script>
<style lang="scss" scoped>
.wrapper__plan-card-current {
  background-repeat: no-repeat;
  background-position: center center;

  .wrapper__plan-card-current-body {
    min-height: 230px;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    padding: 2rem;
  }
}
</style>
