<template>
  <div :key="user.user_business.id">
    <section class="pt-4">
      <b-container fluid class="pb-4 px-lg-4">
        <b-row>
          <b-col xs="12" lg="5" class="mb-3 mb-lg-0">
            <PlansCardComponent />
          </b-col>
          <b-col xs="12" lg="7">
            <NumberStatisticsComponent />
          </b-col>
        </b-row>
        <b-row class="mt-5">
          <b-col xs="12" xl="5" class="mb-4">
            <QuotesToInvoicesComponent />
          </b-col>
          <b-col xs="12" xl="7">
            <CompletedInfoProfileComponent />
            <QuotesRecientComponent />
          </b-col>
        </b-row>
      </b-container>
    </section>
    <OnBoardComponent :show="true" v-if="onBoarding" />
    <ModalNewsComponent :showDialog="showNews" :setNews="news" />
  </div>
</template>
<script>
import PlansCardComponent from "../../plans/components/PlansCard";
import NumberStatisticsComponent from "../components/NumberStatistics";
import ModalNewsComponent from "../components/ModalNews.vue";
import dashboardService from "../services/dashboardService";
import QuotesRecientComponent from "../../sales/pages/quotes/components/QuoteRecient";
import OnBoardComponent from "../../components/OnBoard";
import CompletedInfoProfileComponent from "../components/CompletedInfoProfile";
import QuotesToInvoicesComponent from "../../reports/components/QuotesToInvoices";

export default {
  name: "DashboardHomePage",
  data() {
    return {
      onBoarding: false,
      showNews: false,
      news: null
    };
  },
  computed: {
    user() {
      return this.$store.getters.getUser;
    }
  },
  components: {
    PlansCardComponent,
    NumberStatisticsComponent,
    QuotesRecientComponent,
    ModalNewsComponent,
    OnBoardComponent,
    CompletedInfoProfileComponent,
    QuotesToInvoicesComponent
  },
  methods: {
    async getNews() {
      try {
        let { data } = await dashboardService.getNews().finally(() => {});
        if (data.success) {
          this.showNews = true;
          this.news = data.data;
        }
      } catch (error) {
        return false;
      }
    }
  },
  props: ["query"],
  mounted() {
    if (this.query && this.query.onBoarding) this.onBoarding = true;

    this.getNews();
  }
};
</script>
<style lang="scss"></style>
