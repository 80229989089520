<template>
  <el-dialog
    width="40%"
    title=""
    :visible.sync="outerVisible"
    @close="onCloseDialog"
    class="dialog-onboard"
  >
    <img :src="imgCelebration" alt="Success account" id="success-account" />
    <section class="step step-0" v-if="active === 0">
      <h3 class="h3 text-center font-semi-bold text-color-primary">
        <span class="text-color-secondary">¡Felicidades!</span>
        Has creado <br />
        tu cuenta de Mydessk
      </h3>
      <p>
        Gestiona todos tus proyectos de forma organizada registra y lleva un
        control de todos tus documentos.
      </p>
    </section>
    <section class="step step-0" v-if="active === 1">
      <h3 class="h3 text-center font-semi-bold text-color-primary">
        Completa tu información de empresa
      </h3>
      <p>
        Ingresa a la configuración y personaliza tus cotizaciones con
        información de tu empresa.
      </p>
    </section>

    <section class="step step-0" v-if="active === 2">
      <h3 class="h3 text-center font-semi-bold text-color-primary">
        Ingresar tu catálogo de productos y servicios
      </h3>
      <p>
        Agrega el detalle y descripción de tus productos o servicios, y así
        podrás tener un control sistematizado de tu negocio.
      </p>
    </section>

    <section class="step step-0" v-if="active === 3">
      <h3 class="h3 text-center font-semi-bold text-color-primary">
        Registra tus clientes
      </h3>
      <p>
        Ingresa la información de tus contactos y clientes, y estarás listo para
        comenzar a utilizar Mydessk de manera organizada.
      </p>
    </section>

    <el-steps :active="active" finish-status="success" space="0">
      <el-step title=""></el-step>
      <el-step title=""></el-step>
      <el-step title=""></el-step>
      <el-step title=""></el-step>
    </el-steps>

    <section class="buttons-steps">
      <button @click="previous" class="btn md__btn-secondary" v-if="active > 0">
        Anterior
      </button>
      <button @click="next" class="btn md__btn-primary">
        {{ active !== 3 ? "Siguiente" : "Finalizar" }}
      </button>
    </section>
  </el-dialog>
</template>

<script>
import { imgCelebration } from "@/services/resources";

export default {
  name: "OnBoardComponent",
  data: function() {
    return {
      imgCelebration,
      active: 0,
      outerVisible: this.show
    };
  },
  methods: {
    next() {
      if (this.active !== 3) this.active++;
      else this.outerVisible = false;
    },
    previous() {
      if (this.active > 0) this.active--;
    },
    onCloseDialog() {
      window.history.pushState(null, null, "/dashboard");
    }
  },
  props: {
    show: {
      default: false,
      type: Boolean
    }
  }
};
</script>

<style lang="sass">
.dialog-onboard
  #success-account
    width: 160px;
    margin: 2rem auto;
    display: block;

  .el-dialog
    border-radius: 0.3rem;
    h3
      word-break: break-word;
    p
      margin: 1rem 0;
      text-align: center;
      word-break: break-word;
  .el-dialog__body
    padding-bottom: 0px !important;

    .el-steps
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 3rem;
      .el-step__head
        display: none;
        &.is-process
          border-color: #9a0252;
          .el-step__icon
            background: #9a0252;
        &.is-success, &.is-wait
          border-color: #C0C4CC;
          .el-step__icon
            background: #C0C4CC;
        .el-step__line
          display: none;
        .el-step__icon
          width: 20px;
          height: 20px;
          margin: 0 .5rem;
      .el-step__icon-inner
        display: none;
  .buttons-steps
    display: flex;
    justify-content: space-evenly;
    max-width: 300px;
    margin: 2rem auto;
</style>
