<template>
  <div>
    <div @click.stop="shareLink" class="d-flex w-100">
      <slot></slot>
    </div>

    <el-dialog
      :visible.sync="dialogVisible"
      append-to-body
      class="news-dialog"
      top="0"
    >
      <div style="margin-top: 15px;" v-if="data && data.imageUrl && !data.link">
        <img :src="data.imageUrl" alt="" />
      </div>

      <div
        style="margin-top: 15px;text-align: center;"
        v-if="data && data.link"
      >
        <iframe
          :src="data.link"
          frameborder="0"
          allowfullscreen
          width="560"
          height="315"
        ></iframe>
      </div>

      <div class="text-center mt-2">
        <h3>{{ data?.title }}</h3>
        <span class="text-color-black text-center">{{ data?.content }} </span>
      </div>

      <div slot="" class="text-center">
        <el-button class="btn md__btn-primary m-2" @click.stop="postNoShow"
          >Continuar</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import dashboardService from "../services/dashboardService";

export default {
  name: "ModalNewsComponent",
  data() {
    return {
      dialogVisible: this.showDialog,
      data: this.setNews
    };
  },
  watch: {
    showDialog(v) {
      this.dialogVisible = v;
    },
    setNews(v) {
      this.data = v;
    }
  },
  methods: {
    async postNoShow() {
      try {
        const data = {
          news_id: this.data.id
        };
        await dashboardService.postNewsNoShow(data).finally(() => {});
        this.dialogVisible = false;
      } catch (error) {
        return false;
      }
    }
  },
  props: {
    setNews: {
      type: Object,
      default: () => ({})
    },
    showDialog: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style lang="scss" scoped></style>
