import Api from "@/config/Api";

export default {
  getStatsQuick() {
    return Api().get("/me/stats/quick", {});
  },
  getNews() {
    return Api().get("/news", {});
  },
  postNewsNoShow(data) {
    return Api().post("/news/noshow", data);
  }
};
