<template>
  <section>
    <template v-if="!loading">
      <WrapperCardComponent
        title="Últimas cotizaciones en curso"
        description="Da seguimiento a tus clientes y cierra las ventas"
      >
        <template v-slot:actions>
          <div class="float-right">
            <FilterBetweenDates @onAsyncDataForDates="onAsyncDataForDates" />
          </div>
        </template>
        <template v-slot:content>
          <template v-if="quotes.length">
            <section class="table-items table-items-quotes">
              <b-row class="table-items-header">
                <b-col xs="12" sm="12" md="2">
                  <h6 class="h6 ml-2">Código</h6>
                </b-col>
                <b-col xs="12" sm="12" md="5">
                  <h6 class="h6">Detalle</h6>
                </b-col>
                <b-col xs="6" sm="12" md="2">
                  <h6 class="h6">Estado</h6>
                </b-col>
                <b-col xs="6" sm="12" md="2">
                  <h6 class="h6">Fecha</h6>
                </b-col>
                <b-col xs="4" sm="12" md="1">
                  <h6 class="h6">Valor</h6>
                </b-col>
              </b-row>
              <div
                class="card table-items-body"
                v-for="(item, index) in quotes"
                :key="index"
              >
                <div
                  class="card-body table-items-content"
                  v-if="lastQuoteVersion(item)"
                  @click="quoteView(item, lastQuoteVersion(item))"
                >
                  <b-row class="">
                    <b-col xs="12" sm="12" md="2" class="table-col-item">
                      <span class="item-col-code font-semi-bold">
                        {{ item.code }}
                      </span>
                    </b-col>
                    <b-col xs="12" sm="12" md="5" class="table-col-item">
                      <span
                        class="item-col-detail font-bold text-primary str-limit-space"
                      >
                        {{ lastQuoteVersion(item).name }}
                      </span>
                    </b-col>
                    <b-col xs="6" sm="12" md="2" class="table-col-item">
                      <StatusReadDocumentsComponent
                        :status.sync="item.status"
                      />
                    </b-col>
                    <b-col xs="6" sm="12" md="2" class="table-col-item">
                      <span class="item-col-date font-semi-bold">
                        {{ lastQuoteVersion(item).created_at }}
                      </span>
                    </b-col>
                    <b-col xs="4" sm="12" md="1" class="table-col-item">
                      <span class="font-semi-bold">
                        ${{ lastQuoteVersion(item).total }}
                      </span>
                    </b-col>
                  </b-row>
                </div>
              </div>
            </section>
            <p class="float-right">
              <LinkComponent
                title="Ver más cotizaciones"
                classNames="text-color-secondary"
                @onClick="handleLinkClick"
              />
            </p>
          </template>
          <QuoteEmptyComponent v-else />
        </template>
      </WrapperCardComponent>
    </template>
    <LoadingSkeletonTable :showFilter="false" v-else />
  </section>
</template>

<script>
import { icoOptionGray } from "@/services/resources";
import LoadingSkeletonTable from "@/components/Animations/Loading/SkeletonTable";
import LinkComponent from "../../../../components/Link";
import WrapperCardComponent from "../../../../components/WrapperCard";
import quoteService from "../services/quoteService";
import QuoteEmptyComponent from "./Empty";
import StatusReadDocumentsComponent from "../../../../components/StatusReadDocuments";
import FilterBetweenDates from "../../../../components/FilterBetweenDates";

export default {
  name: "QuotesRecientComponent",
  data: () => ({
    icoOptionGray,
    loading: null,
    searchDates: null,
    quotes: []
  }),
  methods: {
    goRouter(name, params = {}) {
      this.$router.push({ name, params });
    },
    quoteView(quote, version) {
      this.goRouter("sales.quotes.view", {
        id: quote.id,
        idVersion: version.id
      });
    },
    lastQuoteVersion(quote) {
      return quote.versions.find((v, index) => index <= 0);
    },
    onAsyncDataForDates({ searchDates }) {
      this.searchDates = searchDates;
      this.getQuotes();
    },
    async getQuotes() {
      try {
        this.loading = true;
        let response = await quoteService
          .getQuotes({
            params: {
              ...{
                limit: 4,
                status: "pending"
              },
              ...this.searchDates
            }
          })
          .finally(() => (this.loading = false));
        if (response) {
          this.quotes = response.data.quotes;
        }
      } catch (error) {
        return false;
      }
    },
    handleLinkClick() {
      this.goRouter("sales.documents");
    }
  },
  components: {
    LoadingSkeletonTable,
    LinkComponent,
    WrapperCardComponent,
    QuoteEmptyComponent,
    StatusReadDocumentsComponent,
    FilterBetweenDates
  },
  mounted() {
    this.getQuotes();
  }
};
</script>

<style scoped lang="scss">
.table tbody td {
  border-bottom: 10px solid #f2f3f4;
  padding: 20px 14px;
  &:first-child {
    border-bottom-left-radius: 15px;
    border-top-left-radius: 15px;
  }
  &:last-child {
    border-bottom-right-radius: 15px;
    border-top-right-radius: 15px;
  }
}
.table thead th {
  border-top: none;
  border-bottom: 1px solid #f2f3f4;
}
.table tbody tr {
  background: #fff;
}
</style>
